.container-notFound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
 
    color: #FF8C00; /* Orange */
    text-align: center;
}

.errorCode {
    font-size: 6rem;
    font-weight: bold;
    color: aqua;
}

.message {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.homeButton {
    text-decoration: none;
    background-color: #FF8C00; /* Orange */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: bold;
}
