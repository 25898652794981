/* Default Navbar Styling */
.navbar {
 
  transition: background-color 0.3s ease-in-out;
  background-color: rgb(33, 32, 32);
  opacity: 0.9;

}

/* Navbar After Scroll */
.navbar.scrolled {

  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Navbar Brand */
.navbar-brand {
  color: #E67E22 !important;
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 40px;
}

/* Nav Links */
.navbar-nav .nav-link {
  color: white!important;
  font-weight: 500;
  margin-right: 15px;
  transition: color 0.3s ease-in-out;

}

/* Hover Effect */
.navbar-nav .nav-link:hover {
  color: aqua !important;
}

/* Toggler Button */
.navbar-toggler {
  border: none;
  background-color: #E67E22 !important;
 height: 50px;
 
}

.navbar-toggler-icon {
  background-color: #E67E22;
  
  width: 25px;
  height: 2px;
  display: block;
  margin: 6px auto;
  transition: all 0.3s ease-in-out;
}

/* Hamburger Icon Animation */
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  transform: rotate(45deg);
}

/* Mobile Menu */
@media (max-width: 991px) {
  .navbar-collapse {
   
    padding: 10px;
  }

  .navbar-nav .nav-link {
    color: #E67E22!important;
    padding: 10px;
    font-weight: bold;
  
  }

  .navbar-nav .nav-link:hover {
    color: white !important;
  }
}
