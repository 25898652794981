/* Style for form container */
.reservation-form {
  max-width: 450px;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  background: rgba(9, 194, 226, 0.1);
  height: auto; /* Adjusts dynamically based on content */
}

/* Style for labels */
.reservation-form label {
  font-weight: bold;
  color: #ff9900;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;
}

/* Icons inside labels */
.icon-contact {
  color: aqua;
  font-size: 20px;
  margin-right: 8px;
}

/* Input fields styling */
.reservation-form .form-control {
  margin-bottom: 15px;
  border: 1.5px solid #c6d7d7;
  transition: border 0.3s ease-in-out, transform 0.2s ease-in-out;
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
}

/* Input focus effect */
.reservation-form .form-control:focus {
  border-color: #ff9900;
  transform: scale(1.02);
  box-shadow: 0px 0px 6px rgba(230, 126, 34, 0.5);
}

/* Textarea styling */
.reservation-form textarea {
  resize: none;
  min-height: 80px;
}

/* Button styling */
.reservation-form .btn-warning {
  font-weight: bold;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-top: 15px;
}

.reservation-form .btn-warning:hover {
  background-color: #d35400 !important;
  transform: scale(1.05);
}

/* Success alert */
.custom-success-alert {
  background-color: #28a745;
  color: white;
  border-color: #28a745;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}
