.carousel {
  width: 100vw;
  height: 100vh; /* Full viewport height */
}

.carousel-inner,
.carousel-item {
  width: 100%;
  height: 100%;
}

.carousel-item img {
  object-fit: cover; /* Ensures the image covers the area */
  width: 100%;
  height: 100%;
}






