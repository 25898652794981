/* Footer Background */
.footer {
    background-color: #222;
    color: white;
  }
  
  /* Footer Boxes */
  .footer-box {
    padding: 20px;
   
  }
  
  /* Footer Headings */
  .footer-box h4 {
    color: #E77E23;
    font-weight: bold;
    margin-bottom: 15px;
    margin: auto;
    margin-bottom: 15px;
  }
  
  /* Footer Links */
  .footer-links {
    list-style: none;
    padding: 0;
    margin: auto;
  }
  
  .footer-links li {
    margin: 5px 0;
  

 
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  .footer-links a:hover {
    color: #001EFF;
  }
  
  /* Contact Icons */
  .footer-icon {
    color: #E77E23;
    margin-right: 10px;
    font-size: 18px;
   
    
  }
  
  /* Social Media Icons */
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-icon {
    color: white;
    font-size: 24px;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  
  .social-icon:hover {
    transform: scale(1.1);
    color: #E77E23;
  }
  
  /* Footer Bottom Text */
  .footer-text {
    font-size: 14px;
    color: #ccc;
  }
  