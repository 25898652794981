.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background: linear-gradient(to right, #1A1A1A, #008B8B);
    padding: 50px;
  }
  
  .about-content {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 40px;
    max-width: 900px;
    box-shadow: 0 0 20px rgba(255, 140, 0, 0.6);
  }
  
  .profile-pic {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: 4px solid #FF8C00;
    object-fit: cover;
    margin-right: 30px;
  }
  
  .text-content {
    color: white;
    font-family: "Arial", sans-serif;
  }
  
  h1 {
    color: #FF8C00;
    font-size: 28px;
  }
  
  h2 {
    font-size: 22px;
    color: #00FFFF;
    margin-bottom: 15px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .signature {
    font-style: italic;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
  }