/* Style for the city boxes */
.container-cities.col-lg-4, .col-md-6 {
    background-color:#222529  ;
    color:  #E67E22;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center; /* Center the text inside the box */
  }
  
  /* Hover effect */
  
.container-cities.col-lg-4:hover, .col-md-6:hover {
    background-color: #E67E22;
    color: white; /* Change text color on hover for better contrast */
    transform: scale(1.03); /* Optional: add slight scale effect */

  }
  
  /* Ensure the rows are centered */
  
.row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .icon-city{
    color: aquamarine;
    font-size: 30px;
   
  }

 