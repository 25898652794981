.App {
  text-align: center;

  background: linear-gradient(to right, #1a1a1a, #008b8b);
}

/* Apply to all text */
body {
  font-family: "Roboto", sans-serif;
}

/* Headings */
h1,
h2,
h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

/* Buttons & Links */
a,
.btn {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
