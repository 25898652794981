ul.check-list {
    list-style: none; /* Remove default bullets */
    padding-left: 0;
  }
  
  ul.check-list li {
    position: relative;
    padding-left: 25px; /* Space for checkmark */
  }
  
  ul.check-list li::before {
    content: "✔"; /* Unicode checkmark */
    color: #F77F00; /* Orange checkmark */
    font-weight: bold;
    position: absolute;
    left: 0;
  }
  

.icon-service{
    color: aqua;
    font-size: 30px;
    font-weight: bolder;
}

.col-lg-4 {
  padding: 10px; /* Adjust spacing between columns */
}


.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 8px rgba(238, 120, 15, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
backdrop-filter: blur(10px);

}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}

.card-header {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.check-list {
  list-style-type: none;
  padding-left: 0;
}

.check-list li::before {
  content: "✔";
  color: #0dcaf0; /* Bootstrap info color */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-right: 0.5em;
}
