.merci-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #1a1a1a, #008b8b);
  }

  .merci-content {
    text-align: center;
    background: #fff;
    background: linear-gradient(to right, #1a1a1a, #008b8b); 
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .thankyou-text {
    color: #64f285;;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .thankyou-subtext {
    color: #64f285;
    font-size: 1.5rem;
  }
  
  .confirmation-text {
    color: #f8f8f8;
    font-size: 1.1rem;
    margin-top: 10px;
  }
  